.booking-status-container {
  padding: 10px;
  background-color: #f4f4f4;
}

.status-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
}

.half-box {
  height: 100px;
}

.count-mid{
  font-size: 35px;
}

.full-box {
  height: 210px;
}

.icon {
  font-size: 25px;
  color: #0c9aff;
}

.icon-mid {
  font-size: 30px;
  color: #877e84;
}

.count {
  font-size: 30px;
  font-weight: bold;
  padding-left: 8px;
  color: #0c9aff;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.room-details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.room-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.room-details div {
  padding: 5px;
  text-align: center;
  flex: 1;
}

.room-details .room-name {
  border-right: 1px solid #ccc;
}

.room-details .room-timings {
  border-right: 1px solid #ccc;
}

.room-details .meeting-subject {
  border-right: 1px solid #ccc;
}

.room-details .created-by {
  border-right: none;
}

.heading .room-name, 
.heading .room-timings, 
.heading .meeting-subject, 
.heading .created-by {
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1px;
  border-bottom: none;
  text-align: center;
}
