
#meeting-heading{
    font-size: 1.8rem;
    text-align: center;
    font-weight: 500;
}

.meeting-title-heading{
    /* font-size: 0.8rem;
    color: grey; */
    color: black;
    font-size: 16px;
    margin-bottom: 0.3rem;
    
}

.meeting-title{
    font-size: 15px;
    font-weight: 400;
    border: 1.5px solid #9d9b9b;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}
.meeting-time{
    height: fit-content;
    margin-top:0.2rem ;
}

.invitee-symbol{
    aspect-ratio: square;
    border-radius: 100%; 
    background-color: #87CEEB  ;
    width: 2.5rem;
    height: 1.7rem;
    text-align: center;
    color: #000000;
    font-weight: 500;
}
#calendar-popup{
    position: relative;
}
.not-created{
    color: #FF0000;
    /* position: absolute; */
    text-align: right;
    right: 0.5rem;
    margin-top: 1rem;
}
.invitees-label{
    font-size: 15px;

}
#delete-all{
    all: unset;
   padding: 0 1rem;
    background-color: #4096ff;
    color: white;
    border-radius: 0.2rem;
    cursor: pointer;
}

#delete-this{
    cursor: pointer;
    all: unset;
    padding: 0.5rem 1rem;
    border: 1px  solid #d9d9d9;
    color: black;
    border-radius: 0.2rem;
    cursor: pointer;

}

/* .ant-modal-content{
    max-height: 500px;
    overflow-y: scroll;
} */

.popupDiv {
    padding-right: 9px;
  }
  
  .popupDiv::-webkit-scrollbar {
    width: 7px;
  }
  .popupDiv::-webkit-scrollbar-thumb {
    background: #001529;
    border-radius: 7px;
  }
  
  .popupDiv::-webkit-scrollbar-track {
    background-color: #ccc;
    border-radius: 10px;
  }