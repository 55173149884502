.meeting-cards-grid-container {
  padding: 0 10px 10px;
  background-color: #f0f2f5;
}

.meeting-list-heading {
  margin-bottom: 10px;
}

.meeting-list-heading .ant-typography {
  margin: 0; /* Remove default margin from Typography */
}

.meeting-cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.meeting-card {
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: auto;
}

.meeting-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.divider {
  width: 5px;
  height: 40px;
  background-color: #0c9aff;
  margin-right: 8px;
  border-radius: 5px;
}

.header-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-content .ant-typography {
  margin: 0;
}

.attendee-info {
  display: flex;
  align-items: center;
  margin: 10px;
}

.attendee-info .ant-avatar {
  margin-right: 10px;
}

.details-button {
  padding: 10px;
}

.meeting-info,
.location-room-info {
  padding-left: 10px;
  font-size: 20px;
  color: #0c9aff;
}
.location-room-info {
  padding-left: 4px;
}

.meeting-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.details-button {
  padding: 5px 5px;
}

.no-meetings-message,
.loading-spinner {
  grid-column: 1 / span 3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
}
/* ==== */

.modal-divider {
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modal-row {
  display: flex;
  justify-content: 10px;
}

.modal-row strong {
  width: 20%;
}

.modal-row span {
  width: 65%;
}
