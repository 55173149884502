.contentWrapper {
    margin-left: 20px;
    overflow: auto;
  }
  
  .contentWrapper::-webkit-scrollbar {
    width: 10px;
  }
  
  .contentWrapper::-webkit-scrollbar-track {
    background-color: #ccc;
    border-radius: 10px;
  }
  
  .contentWrapper::-webkit-scrollbar-thumb {
    background: #001529;
    border-radius: 10px;
  }
  .ant-modal-content {
    margin-top: 20%;
  }
  .deletePopup {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 15px;
    flex-direction: row-reverse;
  }
  .delete-btn-f1 {
    font-size: 18px;
    font-weight: 500;
  }

  .ant-upload.ant-upload-select{
    height: fit-content !important;
    width: 100% !important;
    margin: 0 !important;
}
.ant-upload-list-item-container {
    width: 100% !important;
  }
  
  .ant-input.ant-input-lg.css-dev-only-do-not-override-1k979oh.ant-input-outlined::-webkit-outer-spin-button,
  .ant-input.ant-input-lg.css-dev-only-do-not-override-1k979oh.ant-input-outlined::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .ant-table.css-dev-only-do-not-override-1k979oh {
    /* height: 402px;  */
  }
  
  /* / client Detail Page Style / */
  .clientDetailTile {
    width: 100%;
    background-color: #e6f4ff;
    padding: 20px 35px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 10px;
  }
  .siEntity {
    border: 1px solid #1677ff;
    color: #1677ff;
    width: fit-content;
    border-radius: 5px;
    padding: 2px 8px;
  }
  
  .spocDetails {
    padding-left: 10px;
  }
  
  /* .detailLabel {
    color: rgba(0, 0, 0, 0.6);
  } */
  
  .contactHeading {
    font-size: 20px;
  }
  
  .contactDetail {
    width: 1100px;
    align-self: center;
  }
  
  .ql-toolbar {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important ;
  }
  .ql-container  {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    height: 25vh !important;
  }
  .rbc-toolbar {
    text-align: center !important;
    width: 85% !important;
  }
  