.rbc-header {
    font-size: 14px;
    height: 36px;
    font-weight: bold;
}

.rbc-header th {
    display: block;
}

.rbc-allday-cell {
    display: none;
}

.rbc-selected-cell,
.rbc-timeslot-group {
  background-color: transparent !important;
}

.week-view-container .rbc-event{
    width: 110% !important;
}

.rbc-event-label{
    font-size: 10px;
    font-weight: 600;
}

.month-view-container .rbc-event{
    width: 100% !important;
}

.rbc-today{
    background-color: #D4F6FF;
}

.month-view-container .rbc-today{
    border: 1px solid #000 !important
}

.rbc-timeslot-group {
    min-height: 52.40px;
}

.week-view-container .rbc-timeslot-group:nth-child(n+1):nth-child(-n+9){
    
  }

.week-view-container .rbc-timeslot-group:nth-child(n+21):nth-child(-n+24) {

  }
  